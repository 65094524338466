.bills_fullWidth {
  max-width: 100% !important;
}

.bills_fullHeight {
  // height: 100%;
}

.bill_ad_left {
  // transform: rotate(270deg);
}

.bill_ad_right {
  // transform: rotate(90deg);
}

.bill_sideBills {
  // border: 1px solid blue;
  // width: 600px;
}
.bills_pdfView {
  height: 80vh !important;
  width: 80vh !important;
}
// .bill_he
.bills_imgStyle {
  height: 80vh;
}
.bills__mainDivStyle {
  white-space: pre-line !important;
}
