.templateCard__imgStyle {
  position: absolute;
  //   height: 200px !important;
  width: 100% !important;
  //   border: 1px solid blue;
}

.templateCards__textStyle {
  //   border: 1px solid blue;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
}

.templateCard__wrapper {
  //   border: 1px solid blue;
  //   min-height: 300px !important;
}
.templateCard__containerWrapper {
  cursor: pointer !important;
  transition: 250ms;
  &:hover {
    transition: 250ms;
    transform: scale(1.1);
  }
}
